export default class CalcConfig {
  constructor (options) {
    const {
      groups = false
    } = options || {}
    return {
      groups
    }
  }
}
