import { v4 } from 'uuid'

export default class CalcGroup {
  constructor (options) {
    const {
      uuid = v4(),
      name = 'new group',
      order = 0
    } = options || {}
    return {
      uuid,
      name,
      order
    }
  }
}
