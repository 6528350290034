import * as types from '../../mutation-types'
import lazyLoading from './lazyLoading'
import protectedReports from './protected-reports'
import publicReports from './public-reports'

// show: meta.label -> name
// name: component name
// meta.label: display label
const state = {
  items: [
    {
      name: 'Main',
      path: '/',
      meta: {
        inSideBar: false
      },
      component: lazyLoading('Index')
    },
    {
      name: 'Config',
      path: '/config',
      meta: {
        icon: 'fa-gear',
        label: 'Настройки',
        can: 'show Config',
      },
      component: lazyLoading('config/Config', false)
    },
    {
      name: 'Users',
      path: '/users',
      meta: {
        icon: 'fa-user',
        label: 'Пользователи',
        expanded: false,
        exact: false,
        can: 'show User'
      },
      component: lazyLoading('users/Users', false)
    },
    {
      name: 'User',
      path: '/users/:id',
      meta: {
        label: 'Пользователь',
        inSideBar: false,
        can: 'show User',
        fail: '/users'
      },
      component: lazyLoading('users/User', false)
    },


    {
      name: 'WhatDoWeTreat',
      path: '/wdwt',
      redirect: { name: 'Symptoms' },
      meta: {
        can: 'show WhatDoWeTreat',
        label: "Что мы лечим",
      },
      component: lazyLoading('what-do-we-treat/Index', false),
      children: [
        {
          name: 'Symptoms',
          path: 'symptoms',
          meta: {
            inSideBar: false,
            label: 'Симптомы',
          },
          component: lazyLoading('what-do-we-treat/Symptoms', false),
          children: [
            {
              name: 'Symptom',
              path: ':id',
              meta: {
                label: 'Симптом',
                inSideBar: false,
                can: 'show WhatDoWeTreat',
              },
              component: lazyLoading('what-do-we-treat/Symptom', false)
            },
          ]
        },
        {
          name: 'Diseases',
          path: 'diseases',
          meta: {
            label: 'Болезни',
            inSideBar: false,
          },
          component: lazyLoading('what-do-we-treat/Diseases', false),
          children: [
            {
              name: 'Disease',
              path: ':id',
              meta: {
                label: 'Болезнь',
                inSideBar: false,
              },
              component: lazyLoading('what-do-we-treat/Disease', false)
            },
          ]
        },
        {
          name: 'Procedures',
          path: 'procedures',
          meta: {
            label: 'Процедуры',
            inSideBar: false,
          },
          component: lazyLoading('what-do-we-treat/Procedures', false),
          children: [
            {
              name: 'Procedure',
              path: ':id',
              meta: {
                label: 'Процедура',
                inSideBar: false,
                can: 'show WhatDoWeTreat',
              },
              component: lazyLoading('what-do-we-treat/Procedure', false)
            },
          ]
        },
      ]
    },

    {
      name: 'Ban',
      path: '/ban',
      meta: {
        icon: 'fa-ban',
        label: 'Ban',
        can: 'show Ban',
      },
      component: lazyLoading('Ban')
    },


    {
      name: 'CaptchaExceptions',
      path: '/captcha-exceptions',
      meta: {
        can: 'show Captcha',
        label: 'Captcha'
      },
      component: lazyLoading('captcha/CaptchaExceptions', false)
    },
    {
      name: 'InformerNotificationsList',
      path: '/notifications',
      meta: {
        label: 'Перетяжки',
        icon: 'notification_important',
        can: 'show InformerNotifications',
        inSideBar: true
      },
      component: lazyLoading('informer/Notifications', false)
    },
    {
      name: 'InformerNotificationEdit',
      path: '/notifications/:id',
      meta: {
        label: 'Перетяжка',
        inSideBar: false,
        can: 'show InformerNotifications',
        fail: '/notifications'
      },
      component: lazyLoading('informer/Notification', false)
    },

    protectedReports,

    {
      name: 'Divider1',
      path: '/divivder',
      meta: {
        label: '--------------------------'
      },
      component: lazyLoading('Divider'),
    },
    {
      name: 'Documents',
      path: '/documents',
      meta: {
        icon: 'fa-file-text',
        label: 'Документы',
        can: 'show Document',
      },
      component: lazyLoading('documents/Documents', false)
    },
    {
      name: 'Document',
      path: '/documents/:id',
      meta: {
        label: 'Документ',
        inSideBar: false,
        can: 'show Document',
        fail: '/documents'
      },
      component: lazyLoading('documents/Document', false)
    },

    {
      name: 'Cart',
      meta: {
        icon: 'fa-calculator',
        label: 'Калькуляция услуг',
        expanded: false,
        can: 'show Cart'
      },
      redirect: { name: 'common-calculator' },
      path: '/calc',
      component: lazyLoading('Calculation'),
      children: [
        {
          name: 'common-calculator',
          path: 'calculator',
          meta: {
            label: 'Общий калькулятор',
            inSideBar: false,
            can: 'show Cart'
          },
          component: lazyLoading('calculator/index', false),
        },
        {
          name: 'DentistryCalculator',
          path: 'calculation/dentistry',
          meta: {
            onlyMainTenant: true,
            inSideBar: false,
            label: 'Стоматология',
            can: 'show Cart'
          },
          component: lazyLoading('cart/Cart', false),
        },
      ]
    },
    {
      name: 'Photos',
      path: '/photos',
      meta: {
        label: 'Фотопоток',
        icon: 'fa-file-photo-o',
        inSideBar: true,
        can: 'show Photo',
        fail: '/'
      },
      component: lazyLoading('files/Photos', false)
    },
    {
      name: 'Photo',
      path: '/photos/:id',
      meta: {
        label: 'Изображение',
        inSideBar: false,
        can: 'show Photo',
        fail: '/photos'
      },
      component: lazyLoading('files/Photo', false)
    },
    {
      name: 'Files',
      path: '/files',
      meta: {
        label: 'Файлы',
        icon: 'fa-file',
        inSideBar: true,
        can: 'show Files',
        fail: '/'
      },
      component: lazyLoading('files/Files', false)
    },
    {
      name: 'File',
      path: '/files/:id',
      meta: {
        label: 'Файл',
        inSideBar: false,
        can: 'show File',
        fail: '/'
      },
      component: lazyLoading('files/File', false)
    },
    {
      name: 'Pages',
      path: '/pages',
      meta: {
        label: 'Страницы',
        icon: 'fa-file-powerpoint-o',
        inSideBar: true,
        can: 'show Pages',
        fail: '/'
      },
      component: lazyLoading('pages/Pages', false)
    },
    {
      name: 'Page',
      path: '/pages/:id',
      meta: {
        label: 'Страница',
        inSideBar: false,
        fail: '/pages'
      },
      component: lazyLoading('pages/Page', false)
    },
    publicReports,
    {
      name: 'PaymentRequest',
      path: 'payment-request',
      meta: {
        label: 'Заявка на оплату',
        can: 'show PaymentRequest',
        inSideBar: true,
      },
      component: lazyLoading('PaymentRequest', false)
    },

    {
      name: 'Cache',
      path: '/cache',
      meta: {
        icon: 'mdi-broom',
        label: 'Кэш',
        inSideBar: true,
        can: 'show Cache'
      },
      component: lazyLoading('Cache', false)
    },
  ]
}

const mutations = {
  [types.EXPAND_MENU] (state, menuItem) {
    if (menuItem.index > -1) {
      if (state.items[menuItem.index] && state.items[menuItem.index].meta) {
        state.items[menuItem.index].meta.expanded = menuItem.expanded
      }
    } else if (menuItem.item && 'expanded' in menuItem.item.meta) {
      menuItem.item.meta.expanded = menuItem.expanded
    }
  }
}

export default {
  state,
  mutations
}
