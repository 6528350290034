import BaseService from './baseService'
import api from '../utils/api'
import getDemoData from '../../../ui/common/tenant'

// import omit from "lodash/omit"
// import get from "lodash/get"

class TenantService extends BaseService {
  get url () {
    return 'tenant/settings'
  }

  tenant () {
    return api.get('tenant')
  }

  update () {
    return super.update('', ...arguments)
  }

  getWebmenu () {
    return api.get(`${this.url}/webmenu`)
  }

  updateWebmenu (menu) {
    return api.patch(`${this.url}/webmenu`, menu)
  }

  getWebHotmenu () {
    return api.get(`${this.url}/webhotmenu`)
  }

  updateWebHotmenu (menu) {
    return api.patch(`${this.url}/webhotmenu`, menu)
  }

  toDto ({
    colorVariables,
    timezone,
    logo,
    defaultLocale,
    imageOnMain,
    favicon,
    title,
    description,
    locales,
    features,
    openGraphImage,
    frontendEnvVariables,
    grayscaleDoctorImages,
    shortTitleWithPlace,
    meta
  }) {
    return {
      colorVariables,
      locales,
      timezone,
      defaultLocale,
      logoId: logo?.id,
      imageOnMainId: imageOnMain?.id,
      faviconId: favicon?.id,
      title,
      description,
      features,
      openGraphImageId: openGraphImage?.id,
      frontendEnvVariables,
      grayscaleDoctorImages,
      shortTitleWithPlace,
      meta
    }
  }
}

export default new TenantService()

