import FileSaver from 'file-saver'
import printService from '@/services/print'
import cartService from '@/services/cart'
// import { makePrintRequestData } from '@/components/Calc/domain'
import CalcConfig from '@/models/CalcConfig'
import CalcCartItem from '@/models/CalcCartItem'
import CalcGroup from '@/models/CalcGroup'

const PRINT_TEMPLATE = '/print/cart/new'

const initialState = (options) => {
  const {
    config = new CalcConfig(),
    cart = [],
    groups = [],
    currentGroup = null,
  } = options || {}
  return {
    config,
    cart,
    groups,
    currentGroup
  }
}

export default {
  namespaced: true,
  state: initialState,
  getters: {
    getPrintData: (state, getters, rootState) => ({ patient, doctor }) => {
      const { fullName, uuid } = rootState.app.currentUser
      return {
        cart: state.cart,
        groups: state.groups,
        author: { fullName, uuid },
        patient,
        doctor,
        datetime: new Date(),
      }
    },
    cartSummary: (state, getters) => {
      return getters.cartItems.reduce((acc, { currency, summary }) => {
        return {
          currency,
          count: acc.count + summary.count,
          price: acc.price + summary.price,
          surcharge: acc.surcharge + summary.surcharge,
        }
      }, { price: 0, surcharge: 0, count: 0 })
    },
    cartItems: (state) => {
      return state.groups.slice().map((group) => {
        const items = state.cart.filter((cart) => cart.group === group.uuid)
        const currency = items.length ? items[0].currency : null
        return {
          ...group,
          items,
          currency,
          summary: items.reduce((acc, { summary }) => {
            return {
              price: acc.price + summary.price,
              surcharge: acc.surcharge + summary.surcharge,
              count: acc.count + 1
            }
          }, { surcharge: 0, price: 0, count: 0 })
        }
      })
      .sort(({ order: a }, { order: b }) => (b - a))
    }
  },
  actions: {
    reset ({ commit }) {
      commit('reset')
    },
    configure ({ commit }, config) {
      commit('push', { config })
    },
    updateGroup ({ state, commit }, options) {
      const idx = state.groups.findIndex(({ uuid }) => uuid === options.uuid)
      if (idx < 0) return
      const group = state.groups[idx]
      commit('updateGroup', { idx, group: new CalcGroup({ ...group, ...options }) })
    },
    updateItem ({ state, commit }, options) {
      const idx = state.cart.findIndex(({ uuid }) => options.uuid === uuid)
      if (idx < 0) return
      const item = state.cart[idx]
      commit('updateItem', { idx, item: new CalcCartItem({ ...item, ...options }) })
    },
    async addItem ({ dispatch, state, commit }, item) {
      if (!state.groups.length) {
        await dispatch('addGroup', new CalcGroup())
      }
        commit('addItem', new CalcCartItem({
          ...item,
          group: state.currentGroup
        }))
    },
    removeItem ({ commit, state }, uuid) {
      const idx = state.cart.findIndex((item) => item.uuid === uuid)
      if (idx < 0) return
      commit('removeItem', idx)
    },
    async addGroup ({ dispatch, state, commit }, group = {}) {
      const name = group.name || 'New group'
      const newGroup = new CalcGroup({ ...group, name, order: state.groups.length })
      const groups = [ ...state.groups, newGroup ]
      commit('push', { groups })
      await dispatch('selectGroup', newGroup.uuid)
      return newGroup
    },
    selectGroup ({ state, commit }, uuid) {
      if (uuid === null) {
        commit('push', { currentGroup: null })
      } else {
        const group = state.groups.find((group) => group.uuid === uuid)
        if (!group) return
        commit('push', { currentGroup: uuid })
      }
    },
    async removeGroup ({ dispatch, commit, state }, uuid) {
      const relatedIndexes = state.cart
        .map((item, idx) => item.group === uuid ? idx : false)
        .filter((item) => item !== false)
      const idx = state.groups.findIndex((group) => group.uuid === uuid)
      const nearGroup = (state.groups[idx - 1] || state.groups[idx + 1])
      if (idx < 0) return
      commit('removeItems', relatedIndexes)
      commit('removeGroup', idx)
      await dispatch('selectGroup', nearGroup ? nearGroup.uuid : null)
    },
    async clearGroup ({ commit, state }, groupUUID) {
      const cart = state.cart.filter(({ group }) => group !== groupUUID)
      commit('push', { cart })
    },
    async print ({ getters }, { patient, doctor }) {
      const pdf = await printService.pdf({
        filename: 'Calc common',
        templateUrl: PRINT_TEMPLATE,
        data: getters.getPrintData({ patient, doctor }),
      })
      FileSaver.saveAs(pdf.bodyBlob, 'Калькуляция услуг.pdf')
    },
    async attach ({ getters }, { patient, doctor }) {
      return cartService.attach({
        templateUrl: PRINT_TEMPLATE,
        data: getters.getPrintData({ patient, doctor }),
        ehrId: patient.id,
      })
    },
    reorderGroups ({ state, commit }, uuids) {
      const orders = uuids.slice().reverse()
      const groups = state.groups.map((group) => {
        return {
          ...group,
          order: orders.indexOf(group.uuid)
        }
      })
      commit('push', { groups })
    }
  },
  mutations: {
    push: (state, payload) => Object.assign(state, initialState({
      ...state,
      ...payload,
    })),
    addItem: (state, item) => state.cart.unshift(item),
    updateItem: (state, { idx, item }) => {
      state.cart.splice(idx, 1, item)
    },
    removeItem: (state, idx) => state.cart.splice(idx, 1),
    removeItems: (state, indexes) => {
      state.cart = state.cart.filter((v, i) => !indexes.includes(i))
    },
    updateGroup: (state, { idx, group }) => {
      state.groups.splice(idx, 1, group)
    },
    removeGroup: (state, idx) => state.groups.splice(idx, 1),
    reset: (state) => Object.assign(state, initialState({
      config: state.config
    }))
  }
}
