import api from '../utils/api'
import printService from './print'

const templateUrl = '/print/cart'

export default {
  printPdf ({ data, filename }) {
    return printService.pdf({ templateUrl, data, filename })
  },

  printCartNew ({ data, filename }) {
    return printService.pdf({
      templateUrl: '/print/cart/new',
      data,
      filename
    })
  },

  attachToAccount ({ data, ehrId }) {
    return api.post('treatment-plans', { templateUrl, data, ehrId })
  },

  attach ({ templateUrl, data, ehrId }) {
    return api.post('treatment-plans', { templateUrl, data, ehrId })
  }
}
