import api from '../utils/api'

export default {
  pdf ({ templateUrl, data, filename }) {
    return api.post('pdf', {
      templateUrl,
      data,
      filename,
    }, {
      responseType: 'blob'
    })
  }
}
