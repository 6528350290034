import lazyLoading from './lazyLoading'

const children = [
  {
    name: 'Practices',
    path: '/practices',
    meta: {
      label: 'Практики',
      icon: 'fa-calculator',
      can: 'show Practices',
      inSideBar: false,
    },
    component: lazyLoading('bi/Practices', false)
  },
  {
    name: 'PLPracticesDoctor',
    path: '/pl-practices-doctor',
    meta: {
      inSideBar: false,
      label: 'PL докторов',
      icon: 'fa-calculator',
      can: 'show PLPracticesDoctor',
    },
    component: lazyLoading('bi/PLPracticesDoctor', false)
  },
  {
    name: 'OperatingIncomePractices',
    path: 'operating-income-practices',
    meta: {
      onlyMainTenant: true,
      label: 'PL практик',
      sublabel: 'для руководителей практик',
      can: 'show OperatingIncomePractices',
      inSideBar: false,
    },
    component: lazyLoading('bi/OperatingIncomePractices', false)
  },
  {
    name: 'PatientDebts',
    path: 'patient-debts',
    meta: {
      onlyMainTenant: true,
      label: 'Долги пациентов',
      can: 'show PatientDebts',
      inSideBar: false,
    },
    component: lazyLoading('bi/PatientDebts', false)
  },
  {
    name: 'WagesFundPractices',
    path: 'wages-fund-practices',
    meta: {
      label: 'Загрузка',
      sublabel: 'по практикам',
      can: 'show WagesFund',
      inSideBar: false,
    },
    component: lazyLoading('bi/WagesFundPractices', false)
  },
  {
    name: 'PracticesSalaryForDoctors',
    path: 'practices-salary-for-doctors',
    meta: {
      onlyMainTenant: true,
      label: 'Расчетный лист',
      sublabel: 'для докторов практик',
      can: 'show PracticesSalaryForDoctors',
      inSideBar: false,
    },
    component: lazyLoading('bi/PracticesSalaryForDoctors', false)
  },
  {
    name: 'DoctorsRates',
    path: 'doctors-rates',
    meta: {
      onlyMainTenant: true,
      label: 'Ставки вознаграждения',
      sublabel: 'по практикам',
      can: 'show DoctorsRates',
      inSideBar: false,
    },
    component: lazyLoading('bi/DoctorsRates', false)
  },
  {
    name: 'TaxDeductions',
    path: 'tax-deductions',
    meta: {
      onlyMainTenant: true,
      label: 'Налоговые вычеты',
      inSideBar: false,
      fail: '/',
      can: 'show TaxDeductions'
    },
    component: lazyLoading('tax-deduction/TaxDeductions', false),
  },
  {
    name: 'TaxDeduction',
    path: 'tax-deductions/:id',
    meta: {
      // onlyMainTenant: true,
      label: 'Налоговый вычет',
      inSideBar: false,
      inTabs: false,
      fail: '/',
      can: 'show TaxDeduction'
    },
    component: lazyLoading('tax-deduction/TaxDeduction', false)
  },
  {
    name: 'QueuePhones',
    path: 'queue-phones',
    meta: {
      onlyMainTenant: true,
      label: 'Очереди телефонии',
      inSideBar: false,
      can: 'show QueuePhones'
    },
    component: lazyLoading('asterisk/QueuePhones', false)
  },
  {
    name: 'CallStatistics',
    path: 'call-statistics',
    meta: {
      onlyMainTenant: true,
      label: 'Статистика по звонкам',
      can: 'show CallStatistics',
      inSideBar: false
    },
    component: lazyLoading('bi/CallStatistics', false)
  },
]

export default {
  name: 'AllReports',
  redirect: { name: children[0].name },
  meta: {
    icon: 'fa-calc',
    label: 'Отчеты',
  },
  path: '/public-reports',
  component: lazyLoading('bi/Index', false),
  children
}
