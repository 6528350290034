import { v4 as uuid } from 'uuid'
import FileSaver from 'file-saver'
import cartService from '@/services/cart'

export const configTypeNames = {
  DENTIST: 'dentist',
}

const fieldTypes = {
  TEXT: 'text'
}

const detailTemplates = {
  [configTypeNames.DENTIST]: [
    { name: 'comment', label: 'Комментарий', type: fieldTypes.TEXT },
    { name: 'tooth', label: 'Зуб', type: fieldTypes.TEXT },
  ]
}

class Config {
  constructor (options) {
    this.type = options.type
    this.detailsTemplate = detailTemplates[this.type]
    if (!this.detailsTemplate) throw Error('Not valid config type')
  }
}

class Group {
  constructor (options = {}) {
    this.uid = uuid()
    this.name = options.name || 'Новый этап'
  }
}

class Service {
  constructor (options) {
    const details = options.details || {}
    this.uid = uuid()
    this.id = options.id
    this.type = options.type
    this.name = options.name
    this.group = options.group
    this.price = options.price
    this.amount = options.amount
    this.details = detailTemplates[this.type].reduce((acc, field) => {
      acc[field.name] = details[field.name]
      return acc
    }, {})
  }
}

class Author {
  constructor (options) {
    if (!options) throw Error('Options not passed')
    this.id = options.uuid
    this.fullName = options.fullName
  }
}

const defaultState = () => {
  return {
    config: null,
    group: '',
    services: [],
    groups: [],
    doctor: null,
    patient: null,
    busy: false,
    author: null
  }
}

export default {
  namespaced: true,

  state: defaultState,

  getters: {
    isEmpty: (state) => {
      return state.services.length === 0
    },

    cart: (state) => {

      const groups = state.groups.reduce((acc, group) => {
        acc[group.uid] = { ...group, services: [], isActive: (state.group === group.uid) }
        return acc
      }, {})

      state.services.forEach((service) => {
        groups[service.group].services.push({ ...service })
      })

      const groupsList = Object.values(groups)

      groupsList.forEach((group) => {
        group.summary = group.services.reduce((acc, service) => {
          acc.amount += service.amount
          acc.price += service.price * service.amount
          return acc
        }, { amount: 0, price: 0 })
      })

      const summary = groupsList.reduce((acc, group) => {
        acc.price += group.summary.price
        acc.amount += group.summary.amount
        return acc
      }, { amount: 0, price: 0 })

      return { groups: groupsList, summary }
    },
    reportData: (state, getters) => {
      return {
        ...getters.cart,
        groups: getters.cart.groups.filter(({ services }) => services.length),
        patient: state.patient,
        config: state.config,
        author: state.author,
        doctor: state.doctor,
        datetime: new Date()
      }
    },
    activeGroup: (state) => {
      return state.groups.find(({ uid }) => (state.group === uid))
    },
    canRemoveGroups: (state) => {
      return state.groups.length >= 2
    },
  },

  actions: {
    error (ctx, e) {
      let error
      if (typeof e === 'string') {
        error = new Error(e)
      } else if (typeof e === 'object') {
        error = e
      }
      alert(error.message)
      throw error
    },

    async attachToAccount ({ dispatch, commit, state, getters }) {
      let error
      commit('set', { busy: true })
      try {
        await cartService.attachToAccount({ ehrId: state.patient.id, data: getters.reportData })
      } catch (e) {
        error = e
      }
      commit('set', { busy: false })
      if (error) dispatch('error', error)
    },

    async print ({ dispatch, commit, getters }) {
      let error
      commit('set', { busy: true })
      try {
        const pdf = await cartService.printPdf({ data: getters.reportData, filename: 'filename' })
        FileSaver.saveAs(pdf.bodyBlob, 'Калькуляция услуг.pdf')
      } catch (e) {
        error = e
      }
      commit('set', { busy: false })
      if (error) dispatch('error', error)
    },

    setEhr ({ commit }, ehr) {
      commit('set', { ehr })
    },

    pushGroup ({ state, commit }, group = {}) {
      const newGroup = new Group(group)
      const groups = state.groups.slice()
      groups.push(newGroup)
      commit('set', { groups, group: newGroup.uid })
    },

    updateGroup ({ state, commit, dispatch }, group) {
      const idx = state.groups.findIndex(({ uid }) => (uid === group.uid))
      if (idx < 0) {
        dispatch('error', `Group ${group.uid} not found`)
      }
      const groups = Object.assign([], state.groups, { [idx]: { ...group } } )
      commit('set', { groups: groups })
    },

    removeGroup ({ state, commit, dispatch, getters }, groupId) {
      if (!getters.canRemoveGroups) {
        dispatch('error', `Can't remove group. At least one group should exist`)
      }
      const idx = state.groups.findIndex(({ uid }) => (uid === groupId))
      if (!idx < 0) {
        dispatch('error', `Can\'t delete group ${groupId}. Group not found`)
      }
      const groups = state.groups.slice()
      groups.splice(idx, 1)
      const prevGroup = state.groups[idx - 1]
      commit('set', {
        services: state.services.filter(({ group }) => (group !== groupId)),
        group: groupId === state.group ? prevGroup ? prevGroup.uid : groups[0].uid : state.group,
        groups
      })
    },

    openGroup ({ commit, state }, uid) {
      const group = state.groups.find((group) => (group.uid === uid))
      if (!group) {
        dispatch('error', `Can\'t open group ${uid}. Group not found`)
      }
      commit('set', { group: group.uid })
    },

    async pushService ({ state, commit, dispatch }, serviceOptions) {
      const foundService = state.services.find(({ group, details, price, id }) => (
        id === serviceOptions.id &&
        group === state.group &&
        details.tooth === serviceOptions.details.tooth &&
        price === serviceOptions.price
      ))

      if (foundService) {
        await dispatch('updateService', { ...foundService, amount: foundService.amount + 1 })
      } else {
        const service = new Service({
          ...serviceOptions,
          type: state.config.type,
          group: state.group,
          amount: serviceOptions.amount || 1
        })
        commit('set', { services: state.services.slice().concat(service) })
      }
    },

    updateService ({ state, commit, dispatch }, service) {
      const idx = state.services.findIndex(({ uid }) => (service.uid === uid))
      if (idx < 0) dispatch('error', `Service ${service.uid} not found`)
      const services = Object.assign([], state.services, { [idx]: { ...service } } )
      commit('set', { services })
    },

    removeService ({ state, commit }, serviceId) {
      const idx = state.services.findIndex(({ uid }) => (uid === serviceId))
      if (idx < 0) dispatch('error', `Can\' remove service ${serviceId}. Service not found`)
      const services = state.services.slice()
      services.splice(idx, 1)
      commit('set', { services })
    },

    init: ({ commit }, options) => {
      const type = options.state ? options.state.config?.type || options.type : options.type
      const group = options.state ? options.state.group : new Group()

      commit('set', {
        config: new Config({ type }),
        groups: options.state ? options.state.groups : [group],
        group: group.uid,
        author: new Author(options.author)
      })


      // if (options.state) {
      //   const data = {}
      // }

      // const errors = validate(options.state)
      //
      // if (errors) {
      //   console.log('Errors:', errors)
      //   dispatch('error', 'Не удалось загрузить сохраненные ранее данные. Форма будет обновлена.')
      // }

    },

    async clean ({ dispatch, commit, state }) {
      const type = state.config.type
      const author = state.author
      commit('reset')
      await dispatch('init', { type, author })
    }
  },

  mutations: {
    set: (state, obj) => {
      Object.keys(state).forEach((key) => {
        const value = obj[key]
        if (value === undefined) return
        state[key] = value
      })
    },

    reset: (state) => {
      Object.entries(defaultState()).forEach(([key, value]) => {
        state[key] = value
      })
    }
  }
}
