import * as types from '../mutation-types'
import AuthUser from '../../models/AuthUser'
import AppConfig from '../../models/AppConfig'
import auth from '../../services/auth'
import configService from '../../services/config'
import tenantService from '../../services/tenant'

export const locales = ['ru', 'en']

const state = {
  device: {
    isMobile: false,
    isTablet: false
  },
  sidebar: {
    opened: false,
    hidden: false
  },
  currentUser: new AuthUser(),
  isAuthenticated: false,
  authLoading: false,
  config: null
}

const actions = {
  async loadConfig ({ commit }) {
    const endpoints = await configService.getEndpoints()
    const tenant = await tenantService.tenant()
    commit(types.SET_CONFIG, new AppConfig({ endpoints, tenant, locales }))
  },
  async whoami ({ commit }) {
    commit(types.SET_AUTH_LOADING, true)
    try {
      let user = await auth.whoami()
      commit(types.SET_CURRENT_USER, { isAuthenticated: true, user })
    } catch (e) {
      commit(types.SET_CURRENT_USER, { isAuthenticated: false, user: null })
    }
    commit(types.SET_AUTH_LOADING, false)
  },
  async login ({ commit, dispatch }, { redirect }) {
    redirect = !redirect || redirect === 'login' ? '/' : redirect
    await auth.login(redirect)
  },
  async logout ({ commit }) {
    const r = await auth.logout()
    if (r.body.providerRedirectUrl) {
      location.href = r.body.providerRedirectUrl
    }
    commit(types.SET_CURRENT_USER, { isAuthenticated: false, user: null })
  }
}

const mutations = {
  [types.TOGGLE_DEVICE] (state, device) {
    state.device.isMobile = device === 'mobile'
    state.device.isTablet = device === 'tablet'
  },

  [types.TOGGLE_SIDEBAR] (state, opened) {
    if (state.device.isMobile) {
      state.sidebar.opened = opened
    } else {
      state.sidebar.opened = true
    }
  },

  [types.SET_CURRENT_USER] (state, { isAuthenticated, user }) {
    state.isAuthenticated = isAuthenticated
    state.currentUser = isAuthenticated ? user :  new AuthUser()
  },

  [types.SET_CONFIG] (state, config) {
    state.config = { ...state.config, ...config }
  },

  [types.SET_AUTH_LOADING] (state, authLoading) {
    state.authLoading = !!authLoading
  }
}

export default {
  state,
  mutations,
  actions
}
