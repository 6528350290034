import { v4 } from 'uuid'

export default class CalcCartItem {
  constructor (options) {
    const {
      id,
      uuid = v4(),
      name,
      price = 0,
      currency,
      surcharge = 0,
      amount = 1,
      note,
      group,
    } = options || {}

    return {
      id,
      uuid,
      name,
      price,
      currency,
      surcharge,
      amount,
      note,
      group,
      summary: {
        price: price * amount,
        surcharge: surcharge * amount
      }
    }
  }
}
