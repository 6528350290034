import lazyLoading from './lazyLoading'

const children = [
  {
    name: 'PaysheetsChiefPractice',
    path: 'paysheets-chief-practice',
    meta: {
      onlyMainTenant: true,
      label: 'Расчетный лист',
      sublabel: 'для глав. врачей (практики)',
      can: 'show PaysheetsChiefPractice',
      inSideBar: false,
    },
    component: lazyLoading('bi/PaysheetsChiefPractice', false)
  },
  {
    name: 'WagesFundPracticesPL',
    path: 'pl-practices-test',
    meta: {
      label: 'Загрузка',
      sublabel: '(графики)',
      can: 'show WagesFund',
      inSideBar: false,
    },
    component: lazyLoading('bi/PLPracticesTest', false)
  },
  {
    name: 'PracticesInsurance',
    path: 'practices-insurance',
    meta: {
      onlyMainTenant: true,
      label: 'Отчет для КЭР',
      can: 'show PracticesInsurance',
      inSideBar: false,
    },
    component: lazyLoading('bi/PracticesInsurance', false)
  },
]

export default {
  name: 'ProtectedReports',
  redirect: { name: children[0].name },
  meta: {
    icon: 'fa-calc',
    label: 'Закрытые отчеты',
  },
  path: '/protected-reports',
  component: lazyLoading('bi/Protected', false),
  children
}
